import Swiper from 'swiper';
import { Pagination, Autoplay, Parallax, Controller, EffectFade } from 'swiper/modules';


export default function home() {
   // check if body has class .home
   if (!document.body.classList.contains('home')) {
      return;
   }

   const imageSlider = new Swiper('.home-slider-image', {
      preloadImages: false,
      slidesPerView: 1,
      loopAdditionalSlides: 2,
      lazy: {
         loadPrevNext: true,
      },
      speed: 800,
      loop: true,
   });


   const mainSlider = new Swiper('.home-slider-content', {

      preloadImages: false,
      modules: [Pagination, Autoplay, Parallax, EffectFade, Controller],
      lazy: {
         checkInView: true,
         loadPrevNext: true,
      },
      effect: 'fade',
      fadeEffect: {
         crossFade: true,
      },
      autoplay: {
         delay: 8000,
         disableOnInteraction: false,
      },
      slidesPerView: 1,
      loopAdditionalSlides: 2,
      spaceBetween: 24,
      freeMode: true,
      speed: 800,
      parallax: true,
      disableOnInteraction: false,
      loop: true,
      pagination: {
         el: '.js-home-slider-pagination',
         clickable: true,
      },
      controller: {
         control: [imageSlider],
      },
      on: {
         afterInit: function () {
            const slides = this.slides.length;
            const random = Math.floor(Math.random() * slides);
            this.slideTo(random, 0, false);
         }
      }
   });

}