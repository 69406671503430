
import Modal from 'bootstrap/js/dist/modal';
let modalInstance;
let video;
let iframe;
let src;
function videoModal() {

   if (!document.querySelector('#js-modal')) {
      return;
   }

   video = document.querySelector('.banner__video');

   const modal = document.querySelector('#js-modal');

   // get links with href starting with #callback
   document.querySelectorAll('a[href*="#play-video"]').forEach((trigger) => {


      trigger.addEventListener('click', (e) => {
         // get the target element
         const target = e.target;

         e.preventDefault();


         // get the modal instance
         modalInstance = new Modal(modal);
         modalInstance.show();

         if (video) {
            video.pause();
         }

         iframe = document.querySelector('.embed-responsive-item');
         src = iframe.getAttribute('data-src');
         iframe.setAttribute('src', src);

      });
   });

   // on modal dismiss
   modal.addEventListener('hidden.bs.modal', () => {
      if (video) {
         video.play();
      }

      src = iframe.getAttribute('src');
      iframe.setAttribute('data-src', src);
      iframe.setAttribute('src', '');
   });

}

export { videoModal, modalInstance };