import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';

export default function archiveImageSlider() {
   const test = document.querySelector('.archive-slider');

   if (!test) {
      return;
   }

   const imageSlider = new Swiper(test, {
      preloadImages: false,
      modules: [Autoplay, EffectFade],
      slidesPerView: 1,
      rewind: true,
      effect: 'fade',
      fadeEffect: {
         crossFade: true
      },
      autoplay: {
         disableOnInteraction: false,
         delay: 13000,
         waitForTransition: false
      },
      speed: 2000,
      on: {
         slideChange: function () {
            const currentSlide = this.slides[this.activeIndex];
            const currentSlideImage = currentSlide.querySelector('picture');
            // hack to force reflow to trigger css animation
            void currentSlideImage.offsetWidth;
            currentSlideImage.classList.add('fade-in');
         },
         slideChangeTransitionEnd: function () {
            const prevSlide = this.slides[this.previousIndex];
            const prevSlidePicture = prevSlide.querySelector('picture');
            prevSlidePicture.classList.remove('fade-in');
         },
      }
   });
}