/* ========================================================================
 * Custom import of Bootstrap modules
 * comment/uncomment the things you do/don't need.
 * import then needed Font Awesome functionality
 * ======================================================================== */
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/offcanvas';

import frontend from 'parentThemeScripts/components/functions-frontend';
import { domReady } from 'parentThemeScripts/components/domReady';
import tags from './components/tags';
import blockProjectSlider from './components/block-project-slider';
import blockWorkfields from './components/block-workfields';
import blockNewsSlider from './components/block-news-slider';
import blockPersonCta from './components/block-person-cta';
import blockArchive from './components/block-archive';
import logoAnim from './components/logo-anim';
import home from './components/home';
import blockMediaTextSlider from './components/block-media-text-slider';
import blockImageSlider from './components/block-image-slider';
import { videoModal } from './components/modal';
import emailSignatureGenerator from './components/signatureGenerator';
import blockKnowledgeBase from './components/block-knowledgebase';
import archiveImageSlider from './components/archive-slider';
import map from '../../../blocks/vinu-map';

/**
 * Run the application when the DOM is ready.
 */
domReady(() => {
    videoModal();
    frontend();
    tags();
    home();
    blockProjectSlider();
    blockWorkfields();
    blockNewsSlider();
    blockPersonCta();
    blockArchive();
    blockMediaTextSlider();
    blockImageSlider();
    logoAnim();
    blockKnowledgeBase();
    new emailSignatureGenerator();
    archiveImageSlider();
    map();
});

/**
 * Run the application when the jQuery is ready.
 * We encourage you to use vanilla JS, but if you prefer, you can use jQuery by uncommenting the next block.
 */

/* $(() => {
    // import your jQuery code here
}); */

/**
 * Accept module updates
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
import.meta.webpackHot?.accept(console.error);
