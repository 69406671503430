import Swiper from 'swiper';
import { Navigation, Autoplay, Scrollbar } from 'swiper/modules';


export default () => {
   const sliderElements = document.querySelectorAll('.news-swiper');
   if (!sliderElements) return;

   sliderElements.forEach((item) => {

      var pagination = item.querySelector('.swiper-progress'),
         next_el = item.querySelector('.js-slide-next'),
         offset = item.dataset.offset == "1" ? true : false;

      new Swiper(item, {
         preloadImages: false,
         modules: [Navigation, Scrollbar, Autoplay],
         lazy: {
            loadPrevNext: true,
         },
         slidesPerView: 1.1,
         loopAdditionalSlides: 1,
         spaceBetween: 24,
         freeMode: true,
         rewind: true,
         speed: 300,
         parallax: true,
         disableOnInteraction: false,
         scrollbar: {
            el: pagination,
            draggable: true,
         },
         breakpoints: {
            768: {
               initialSlide: offset ? 2 : 0,
               slidesPerView: 2,
               loopAdditionalSlides: 2,
            },
         },
      });
   });
}
