const namespace = 'wp-lemon';

/**
 * Bind this function to an element to scroll to the next sibling element by defining your desired parent element.
 * 
 * @api {function}
 * @param {Element} el The element that fired the event
 * @param {string} parentSelector The class of the parent element to find, default is 'acf-block'
 * @returns void
 */
function scrollNext(el, parentSelector = 'acf-block') {

   if (!el) {
      return;
   }

   el.addEventListener('click', function (e) {
      e.preventDefault();

      const parent = findParentBlock(el, parentSelector);
      const next = parent.nextElementSibling;
      next.scrollIntoView({ behavior: "smooth", block: "center" });
   });
}


/**
 * @api {function} findParentBlock(el) findParentBlock
 * @param {Element} el the element that fired the event 
 * @param {string} parent the class of the parent element to find, default is 'acf-block'
 * @returns {Element} the parent element with the class .acf-block
 */
function findParentBlock(el, parent = 'acf-block') {
   // traverse up the dom to find the element with the class .acf-block
   var attempts = 0;
   var parentElement = el;
   const maxAttempts = 10;

   while (parentElement && !parentElement.classList.contains(parent)) {

      if (attempts > maxAttempts) {
         return false;
      }

      parentElement = parentElement.parentElement;
      attempts++;
   }

   return parentElement;
}


/**
 * Function to fire custom events
 * 
 * You can listen for events triggered by this function by using the following syntax:
 * 
 * document.addEventListener('wp-lemon/$module/$eventName', function(e) {
 *    console.log(e.detail);
 * });
 * 
 * @param {string} eventName event name prefixed with namespace
 * @param {string} module module name
 * @param {object} details data to pass to event 
 */
function trigger(eventName, module, details = false) {
   const name = `${namespace}/${module}/${eventName}`;
   const event = new CustomEvent(name, {
      bubbles: true,
      cancelable: true,
      detail: details
   });

   document.dispatchEvent(event);
}


export { findParentBlock, trigger, scrollNext };