import { ajaxCall, setTerms } from 'parentThemeScripts/components/archive';

export default function blockKnowledgeBase() {
   const archives = document.querySelectorAll('.js-archive');
   archives.forEach((archive) => {
      var loadMoreBtn = archive.querySelector('.js-archive-loadmore-btn');
      var filters = archive.querySelectorAll('.js-knowledgebase-filter');
      console.log(filters);
      if (filters) {

         filters.forEach((filter) => {
            filter.addEventListener('click', (e) => {

               filters.forEach(function (element) {
                  element.classList.remove("filter-button--active");
               });

               filter.classList.add('filter-button--active');
               var terms = filter.value;

               if (terms == 'all') {
                  terms = null;
               }
               setTerms(archive, terms);
               ajaxCall(archive, true);
            });
         })


      }
   });
}
