import Swiper from 'swiper';
import { Navigation, Scrollbar, Autoplay, Parallax, EffectFade, Mousewheel } from 'swiper/modules';
export default () => {
   const sliderElements = document.querySelectorAll('.project-swiper');
   if (!sliderElements) return;

   sliderElements.forEach((item) => {

      var pagination = item.querySelector('.swiper-progress'),
         next_el = item.querySelector('.js-slide-next'),
         offset = item.dataset.offset == "1" ? true : false;
      const swiper = new Swiper(item, {
         preloadImages: false,
         modules: [Navigation, Scrollbar, Autoplay, Parallax, EffectFade, Mousewheel],
         lazy: {
            loadPrevNext: true,
         },
         mousewheel: {
            forceToAxis: true,
         },
         slidesPerView: 1.1,
         spaceBetween: 24,
         freeMode: true,
         speed: 400,
         parallax: true,
         initialSlide: offset ? 1 : 0,
         disableOnInteraction: false,
         rewind: true,
         scrollbar: {
            el: pagination,
            draggable: true,
         },
         navigation: {
            nextEl: next_el,
         },
         breakpoints: {
            768: {
               slidesPerView: 1,
            },
         },
      });




      // swiper.scrollbar.setTranslate(500);
   });
}
