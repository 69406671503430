import LazyLoad from 'vanilla-lazyload/dist/lazyload.min.js';
export default () => {
   const fields = document.querySelectorAll('.workfields__item');
   const highlight = document.querySelector('.js-highlight-image');

   if (fields.length > 0) {
      fields.forEach(item => {
         item.addEventListener('mouseenter', function () { touchHandler(item) });
         item.addEventListener('touchstart', function () { touchHandler(item) });
      });
   }

   function touchHandler(item) {
      var image = item.dataset.bg;
      var imageEL = highlight.querySelector('img');

      imageEL.dataset.src = image;
      LazyLoad.load(imageEL);
   }
}