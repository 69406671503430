import Swiper from 'swiper';
import { Navigation, Scrollbar, Autoplay, Parallax, EffectFade, Mousewheel } from 'swiper/modules';
export default () => {
   const sliderElements = document.querySelectorAll('.image-swiper');
   if (!sliderElements) return;

   sliderElements.forEach((item) => {
      var pagination = item.querySelector('.swiper-progress');
      const swiper = new Swiper(item, {
         preloadImages: false,
         modules: [Navigation, Scrollbar, Autoplay, Parallax, EffectFade, Mousewheel],
         lazy: {
            loadPrevNext: true,
         },
         mousewheel: {
            forceToAxis: true,
         },
         slidesPerView: 1.1,
         spaceBetween: 24,
         freeMode: true,
         speed: 400,
         parallax: true,
         initialSlide: 1,
         disableOnInteraction: false,
         rewind: true,
         scrollbar: {
            el: pagination,
            draggable: true,
         },
         breakpoints: {
            768: {
               slidesPerView: 1,
            },
         },
      });




      // swiper.scrollbar.setTranslate(500);
   });
}
