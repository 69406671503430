import Swiper from 'swiper';
import { Navigation, Autoplay, Controller, EffectFade, Parallax } from 'swiper/modules';

export default () => {

   const thumbs = new Swiper('.media-text-swiper-thumbs', {
      modules: [Autoplay, Controller, Parallax],
      slidesPerView: 1,
      slidesPerGroup: 1,
      parallax: true,
      allowTouchMove: false,
      freeMode: true,
      speed: 600,
      spaceBetween: 24,
      slideToClickedSlide: true,
      on: {
         slideChange: function () {
            // css transform this.el 
            var index = this.activeIndex

            if (index > 0) {
               this.el.classList.add('is-active');
            } else {
               this.el.classList.remove('is-active');
            }
         },
      },
   });

   const content = new Swiper(".media-text-swiper-content", {
      spaceBetween: 0,
      modules: [EffectFade, Parallax, Controller, Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 600,
      effect: 'fade',
      allowTouchMove: false,
      fadeEffect: {
         crossFade: true
      },
      parallax: true,
      navigation: {
         nextEl: '.media-text-slider__button--next',
         prevEl: '.media-text-slider__button--prev',
      },
   });

   content.controller.control = thumbs;
   thumbs.controller.control = content;
   thumbs.controller.by = 'slide';
   content.controller.by = 'slide';


   thumbs.on('slideChange', function () {
      // var translate = this.getTranslate()
      // thumbs.setTranslate(translate - 50);
   });
}