import gmapsInit from './gmapinit';
import mapRestCall from './mapRestCall';
import styling from './mapStyling';
export default function map() {
   const mapEl = document.querySelector('#js-map');
   if (!mapEl) return;

   mapInit(mapEl);
}

async function mapInit(mapEl) {
   try {
      var google = await gmapsInit();
      await setupMap(google, mapEl);
   } catch (error) {
      // eslint-disable-next-line
      console.error(error);
   }
}


function setupMap(google, mapEl) {
   var map;

   var bounds = new google.maps.LatLngBounds();

   map = new google.maps.Map(mapEl, {
      styles: styling,
      scrollwheel: !1,
      minZoom: 2,
      center: new google.maps.LatLng(51.923892, 4.492651),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: false,
      maxZoom: 10,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      rotateControl: false,
   });
   mapRestCall(map, bounds);
   var mapModal = document.getElementById('map_modal');

   map.controls[google.maps.ControlPosition.RIGHT_TOP].push(mapModal);

}