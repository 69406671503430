export default () => {
   // add eventlisteren foreach
   const tags = document.querySelectorAll('.tag--button');
   tags.forEach(tag => {

      if (tag.dataset.active == 'true') {
         return
      }

      tag.dataset.active = 'true';
      tag.addEventListener('click', function (e) {

         e.preventDefault();
         var parent = this.parentNode;

         var hidden = parent.querySelectorAll('.tag--hidden');
         tag.classList.add('tag--hidden');
         hidden.forEach(tag => {
            tag.classList.toggle('tag--hidden');
         });
      });
   });

}