import lottie from 'lottie-web/build/player/lottie_light';

export default function logoAnim() {
    var headerAnim;
    var headerAnimParams = {
        container: document.getElementById('js-header-logo-anim'),
        loop: false,
        autoplay: true,
        path: vinu.logodata,
    };
    headerAnim = lottie.loadAnimation(headerAnimParams);
}
export { logoAnim };
