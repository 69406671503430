import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Parallax, EffectFade } from 'swiper/modules';

export default () => {
   const sliderElements = document.querySelectorAll('.person-swiper');
   if (!sliderElements) return;

   sliderElements.forEach((item) => {
      var pagination = item.querySelector('.swiper-progress'),
         next_el = item.querySelector('.js-slide-next');
      new Swiper(item, {
         preloadImages: false,
         modules: [Navigation, Pagination, Autoplay, Parallax, EffectFade],
         lazy: {
            loadPrevNext: true,
         },
         slidesPerView: 2.1,
         loopAdditionalSlides: 4,
         spaceBetween: 24,
         freeMode: true,
         rewind: true,
         speed: 800,
         parallax: true,
         disableOnInteraction: false,
         navigation: {
            nextEl: next_el,
         },
         breakpoints: {
            768: {
               spaceBetween: 80,
               slidesPerView: 1.6,
            },
         },
      });
   });
}
