import { lazyInstance } from 'parentThemeScripts/components/lazyLoad';

export default function openMapModal(contents) {

    const mapModal = document.getElementById('map_modal'),
        close = document.getElementById('js-modal-dismiss');

    const modal = {
        image: document.querySelector('.map-modal__img'),
        title: document.querySelector('.map-modal__title'),
        content: document.querySelector('.map-modal__intro')
    }

    modal.title.innerHTML = decodeURI(contents.title);

    modal.image.src = contents.thumbnail;

    var text = '<p>' + contents.excerpt + '</p>';

    console.log(contents.link);
    if (contents.link) {
        text += '<a target="_blank" href="' + contents.link + '" class="theme-button theme-button--inline">Bekijk bericht</a>';
    }

    modal.content.innerHTML = text;

    setTimeout(function () {
        lazyInstance.update();
    }, 200);

    mapModal.attributes['aria-hidden'].value = false;
    mapModal.attributes['aria-modal'].value = true;
    mapModal.classList.add('is-active');

    close.addEventListener('click', function () {
        mapModal.attributes['aria-hidden'].value = true;
        mapModal.attributes['aria-modal'].value = false;
        mapModal.classList.remove('is-active');
    });
}
