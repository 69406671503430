import { trigger } from "../api/helpers";

const module = 'cookiebar';

/**
 * Main function
 */
export default function setupCookieBar() {
   const cookieBar = document.getElementById('js-cookiebar');
   var customize;
   var acceptButton;
   var rejectButton;
   var startup = false;
   var cookiePreferencesSet = false;

   if (!cookieBar) {
      /**
       * If we do not have a cookiebar, still trigger the cookiesAllowed event.
       * This is useful for plugins that depend on this event.
       * @see /resources/views/components/tagmanager/head.twig
       */
      console.debug('No cookiebar found, triggering cookiesAllowed event.');
      trigger('cookiesAllowed', module);

      setConsent({
         necessary: true,
         analytics: true,
         preferences: true,
         marketing: true
      });

      window.dataLayer.push({
         'event': 'cookie_consent_update',
      });

      return;
   }

   // Get the users current cookie selection
   var currentCookieSelection = getCookie();

   /**
    * If cookies are disallowed, delete all the cookies at every refresh
    * @param null
    * @return null
    */
   if (currentCookieSelection == 'CookiesDisallowed') {
      trigger('CookiesDisallowed', module);

      setConsent({
         necessary: false,
         analytics: false,
         preferences: false,
         marketing: false
      });

   } else if (currentCookieSelection == 'CookiesAllowed') {
      trigger('cookiesAllowed', module);
   }

   /**
    * Load plugin only if needed:
    * show if the "always" parameter is set
    * do nothing if cookiebar cookie is set
    * show only for european users
    * @param null
    * @return null
    */
   startup = true;
   initCookieBar();

   /**
       * Write cookieBar's cookie when user accepts cookies :)
       * @param {string} name - cookie name
       * @param {string} value - cookie value
       * @return null
       */
   function setCookie(name, value) {
      var exdays = 30;

      var exdate = new Date();
      exdate.setDate(exdate.getDate() + parseInt(exdays));
      var cValue = encodeURI(value) + ((exdays === null) ? '' : '; expires=' + exdate.toUTCString() + ';path=/');
      document.cookie = name + '=' + cValue;
   }

   /**
       * Get Cookie Bar's cookie if available
       * @return {string} cookie value
       */
   function getCookie() {
      var cookieValue = document.cookie.match(/(;)?cookiebar=([^;]*);?/);

      if (cookieValue == null) {
         return undefined;
      } else {
         return decodeURI(cookieValue[2]);
      }
   }

   /**
    * Initialize cookieBAR according to the startup / consentGiven values.
    * @return null
    */
   function initCookieBar() {
      var accepted = getCookie();
      if (accepted === undefined) {
         startup = true;
      } else {
         cookiePreferencesSet = true;
         return;
      }

      if (startup === true && cookiePreferencesSet === false) {
         startCookieBar();
      }
   }

   /**
    * Load external files (css, language files etc.)
    * @return null
    */
   function startCookieBar() {
      // done
      acceptButton = document.getElementById('js-cookie-bar-accept'); // done
      rejectButton = document.getElementById('js-cookie-bar-reject'); // done
      customize = document.getElementById('js-cookie-bar-adjust'); // done

      setEventListeners();
      trigger('startCookieBar', module);
      cookieBar.classList.add('cookiebar--visible');
   }

   /**
    * Set button actions (event listeners)
    * @return null
    */
   function setEventListeners() {
      var analytics = false;
      var marketing = false;
      var preferences = false;

      acceptButton.addEventListener('click', function () {

         if (document.getElementById('cookiebar-analytics') !== null) {
            analytics = document.getElementById('cookiebar-analytics').checked;
         }

         if (document.getElementById('cookiebar-marketing') !== null) {
            marketing = document.getElementById('cookiebar-marketing').checked;
         }

         if (document.getElementById('cookiebar-preferences') !== null) {
            preferences = document.getElementById('cookiebar-preferences').checked;
         }

         cookieBar.classList.remove('cookiebar--visible');

         setCookie('cookiebar', 'CookiesAllowed');
         trigger('cookiesAllowed', module);

         setConsent({
            necessary: true,
            analytics: analytics,
            preferences: preferences,
            marketing: marketing
         });

         window.dataLayer.push({
            'event': 'cookie_consent_update',
         });

      });

      rejectButton.addEventListener('click', function () {

         setCookie('cookiebar', 'CookiesDisallowed');
         trigger('cookiesDisallowed', module);

         cookieBar.classList.remove('cookiebar--visible');

         setConsent({
            necessary: false,
            analytics: false,
            preferences: false,
            marketing: false
         });

         window.dataLayer.push({
            'event': 'cookie_consent_update',
         });

      });

      customize.addEventListener('click', function () {
         customize.dataset.opened = true;
      });
   }

   function setConsent(consent) {
      const consentMode = {
         'functionality_storage': consent.necessary ? 'granted' : 'denied',
         'security_storage': consent.necessary ? 'granted' : 'denied',
         'ad_storage': consent.marketing ? 'granted' : 'denied',
         'ad_user_data': consent.marketing ? 'granted' : 'denied',
         'ad_personalization': consent.marketing ? 'granted' : 'denied',
         'analytics_storage': consent.analytics ? 'granted' : 'denied',
         'personalization_storage': consent.preferences ? 'granted' : 'denied',
      };
      gtag('consent', 'update', consentMode);
      localStorage.setItem('consentMode', JSON.stringify(consentMode));
   }
}
