import openMapModal from './openMapModal';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
export default function mapRestCall(map, bounds) {


    const endpoint = wplemon.rest + 'wp/v2/markers/';

    var request = new XMLHttpRequest();
    request.open('GET', endpoint, true);

    request.onload = function () {
        var data = JSON.parse(this.response);



        if (request.status >= 200 && request.status < 400) {
            var markers = [];
            data.forEach((element) => {


                if (!element.location) {
                    console.warn(contents.name + ' Does not have a valid location and is thereby ignored.');
                    return;
                }

                var coords = new google.maps.LatLng(parseFloat(element.location.lat), parseFloat(element.location.lng));
                var marker = new google.maps.Marker({
                    position: coords,
                    icon: {
                        url: decodeURI(element.pin),
                        size: new google.maps.Size(40, 60),
                        anchor: new google.maps.Point(20, 60),
                    },
                    map: map,
                    title: decodeURI(element.title),
                    animation: google.maps.Animation.DROP,
                });

                markers.push(marker);
                marker.addListener('click', function () {
                    openMapModal(element);
                });

                var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

                bounds.extend(loc);
            });

            //var hack = new google.maps.LatLng(9.94757, 21.68538); // center around europe
            map.fitBounds(bounds); // fit all markers in the bounds
            map.panToBounds(bounds); // pan to the bounds
            //map.setCenter(hack); // set center



            var markerCluster = new MarkerClusterer(map, markers, {
                minimumClusterSize: 2,
                styles: clusterStyle,
                imagePath: false,
                clusterClass: 'custom-clustericon',
            });

            var clusterStyle = [
                MarkerClusterer.withDefaultStyle({
                    width: 30,
                    height: 70,
                    url: false,
                    textColor: '#ff00ff',
                    textSize: 10,
                }),
            ];


        } else {
            console.warn('error');
        }
    };
    request.send();
}
