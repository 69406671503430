
export default class emailSignatureGenerator {

   // add constructor to run code only when js-signature-form is present
   signatureForm = null;
   result = null;
   copy = null;

   constructor() {

      this.signatureForm = document.querySelector('#js-signature-form');
      this.result = document.querySelector('#js-signature-result');
      this.copy = document.querySelector('#js-signature-copy');

      if (this.signatureForm) {
         this.init();
      }
   }

   init() {
      console.log('Signature Generator Loaded');
      // add submit event listener to form
      this.signatureForm.addEventListener('submit', (e) => {
         e.preventDefault();
         this.generateSignature(e);
      });

      // add click event listener to copy button
      this.copy.addEventListener('click', (e) => {
         e.preventDefault();
         this.copyToClipboard();
      });
   }

   async generateSignature(e) {


      var action = 'generate_signature',
         url = wplemon.ajax,
         nonce = wplemon.ajax_nonce,
         data = new FormData(this.signatureForm);

      data.append('action', action);
      data.append('nonce', nonce);
      data.append('weekdays', this.getWeekdays());

      const response = await fetch(url, {
         method: 'POST',
         credentials: 'same-origin',
         body: data,
      });

      const html = await response.json();

      // add html to the iframe element body
      this.result.contentWindow.document.body.innerHTML = html;

      if (navigator.clipboard) {
         this.copy.classList.remove('d-none');
         return;
      }


   }

   getWeekdays() {
      // get all the checkboxes
      const checkboxes = this.signatureForm.querySelectorAll('input[type="checkbox"]');

      // create an empty array to store the weekdays
      let weekdays = [];

      // loop through the checkboxes
      checkboxes.forEach((checkbox) => {

         // if the checkbox is checked
         if (checkbox.checked) {

            // add the value to the weekdays array
            weekdays.push(checkbox.value);
         }
      });

      // return the weekdays array
      return weekdays;
   }

   copyToClipboard() {

      // get the iframe body
      const iframeBody = this.result.contentWindow.document.body;

      // select all the content
      // copy to clipboard via web api

      // use the navigator clipboard api if it exists


      if (navigator.clipboard) {

         const clipboardItem = new ClipboardItem({
            "text/plain": new Blob(
               [iframeBody.innerText],
               { type: "text/plain" }
            ),
            "text/html": new Blob(
               [iframeBody.outerHTML],
               { type: "text/html" }
            ),
         });

         navigator.clipboard.write([clipboardItem]);
      }


      // alert the user
      alert('Copied to clipboard');

   }

}